import request from "@/utils/request";

export function getHandleMessageStatus() {
  return request({
    url: '/handle/status',
  })
}

export function getMessageList(handle, page, pageSize) {
  return request({
    url: '/message',
    params: {
      handle,
      page,
      pageSize,
    }
  });
}
