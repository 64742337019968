<template>
  <div class="chat-wrap">
    <div class="chat" v-if="showChatButton && validUsers.length" @click="openChat">
      <img :src="require('@/assets/images/chat-oepn.png')" alt="">
    </div>

    <van-popup v-model="showUserPicker" position="bottom" :style="{ height: '50%' }" class="goods-picker" >
      <van-picker
          title="请选择你的店铺"
          show-toolbar
          :columns="validUsersNames"
          @confirm="selectUser"
          @cancel="showUserPicker = false"
      >
      </van-picker>
    </van-popup>
  </div>
</template>

<script>
import {showChatBox, validUserFilter} from "@/utils/chat";

export default {
  name: "index",
  data() {
    return {
      // 显示用户选择面板
      showUserPicker: false,
    };
  },
  computed: {
    userInfo: function () {
      return this.$store.state.userInfo;
    },
    // 绑定的有效用户（vip 没过期的）
    validUsers: function () {
      // console.log('userInfo', this.userInfo);
      return validUserFilter(this.userInfo);
    },
    validUsersNames: function () {
      const validUsersNames = [];
      this.validUsers.forEach(user => {
        validUsersNames.push(user.info.shopName ? user.info.shopName : user.info.name)
      });
      // console.log('validUsersNames', validUsersNames)
      return validUsersNames;
    },
    showChatButton: function () {
      return this.$store.state.showChatButton;
    }
  },
  methods: {
    openChat() {
      if (this.validUsers.length === 1) {
        showChatBox(this.validUsers[0]);
      } else {
        this.showUserPicker = true;
      }
    },
    selectUser(value, index) {
      showChatBox(this.validUsers[index]);
      this.showUserPicker = false;
    }
  }
}
</script>

<style scoped lang="less">
.chat{
  position: fixed;
  bottom: 42px;
  right: 22px;
  img{
    width: 4.8rem;
  }
}
</style>

<style>
#_aihecong_chat_container_body ._aihecong_chat_iframe._aihecong_chat_iframe_mobile._aihecong_chat_iframe_part{
  height: 100%!important;
  max-height: 100%!important;
}
</style>
