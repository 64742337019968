import store from "@/store";
import {SET_SHOW_CHAT_BOX, SET_SHOW_CHAT_BUTTON} from "@/store/mutation-types";

// 导入第三方js
export function importThirdPart() {
  if (!window._AIHECONG) {
    (function(d, w, c) {
      var s = d.createElement('script');
      w[c] = w[c] || function() {
        (w[c].z = w[c].z || []).push(arguments);
      };
      s.async = true;
      s.src = 'https://static.ahc.ink/hecong.js';
      if (d.head) d.head.appendChild(s);
    })(document, window, '_AIHECONG');
    window._AIHECONG('ini', { channelId: 'T5Icf5', button: false });
    // console.log('init');
    store.commit(SET_SHOW_CHAT_BUTTON, false);

    // 聊天窗口显示和隐藏的回调
    window._AIHECONG('visibleCallback', function (visible){
      if(visible === 'show'){
        store.commit(SET_SHOW_CHAT_BOX, true);
      } else {
        store.commit(SET_SHOW_CHAT_BOX, false);
      }
    });
  }
}

// 过滤有效用户
export function validUserFilter(userInfo){
  // console.log('userInfo', userInfo)
  const validUsers = [];
  if (userInfo && userInfo.users) {
    userInfo.users.forEach(user => {
      if (user.hasNotExpiredVip) {
        validUsers.push(user);
      }
    });
  }

  return validUsers;
}

// 显示聊天系统
export function showChatSystem() {
  const userInfo = store.state.userInfo;
  // console.log('userInfo', window._AIHECONG, userInfo ? userInfo.hasNotExpiredVip : null);
  const validUsers = validUserFilter(userInfo);
  if (validUsers.length) {
    if (window._AIHECONG) {
      store.commit(SET_SHOW_CHAT_BUTTON, true);
    }
  } else {
    store.commit(SET_SHOW_CHAT_BUTTON, false);
    if (window._AIHECONG) {
      window._AIHECONG('hideChat')
      window._AIHECONG('update', { channelId: 'T5Icf5', button: false });
    }
  }
}

// 显示聊天窗口
export function showChatBox(user) {
  store.commit(SET_SHOW_CHAT_BOX, true);
  if (window._AIHECONG) {
    let vipInfo = [];
    user.info.vipInfo.forEach((vip) => {
      vipInfo.push(`${vip.name}(${vip.expireDay})`);
    });
    vipInfo = vipInfo.join(", ");
    // console.log(vipInfo);
    // 初始化
    window._AIHECONG('update',{ channelId : 'T5Icf5', 'uniqueId': user.info.id, button: false });
    // 同步vip信息
    window._AIHECONG('customer',{
      '名称' : user.info.name,
      '用户名' : user.info.name,
      '店铺名' : user.info.shopName ? user.info.shopName : '(未授权)',
      '开通的功能' : vipInfo.replace(/,/, "\n", vipInfo),
      '客户端' : '微信即时消息 后台',
    })
    window._AIHECONG('showChat')
  }
}

// 关闭聊天窗口
export function closeChatBox() {
  store.commit(SET_SHOW_CHAT_BOX, false);
  if (window._AIHECONG) {
    window._AIHECONG('hideChat')
  }
}

